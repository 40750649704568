.cards-container {
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    
  @media #{$small-and-down} {
      @include column-count(1);
  }
  @media #{$medium-and-up} {
      @include column-count(2);
  }
  @media #{$large-and-up} {
      @include column-count(3);
  }
}

    .card {
      display: inline-block;
      overflow: visible;
      border-radius: 5px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 3px 1px -2px rgba(0, 0, 0, 0.02), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
      margin-bottom: 30px;

    .card-image{

      i{
        background-color: #26A69A;
        color: rgba(255, 255, 255, 0.5);
        width: 100%;
        text-align: center;
        vertical-align: middle;
        line-height: 3em;
        font-size: 5em;
      }

      img{
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        @media screen and ( max-width: 992px ){
          margin: 0 auto;
          @include border-radius(50%)
        }
      }
    }

    .card-content{
      @media screen and ( max-width: 992px ){
        background: #ffffff;
        @include border-radius(5px);
      }
    }

    &.box_like{
      width: 100%;
      margin: 0 0 0 0;
      height: auto;
      background: #ffffff;
      @include border-radius(5px);
      box-shadow: 0 10px 30px rgba(0, 0, 0, .1);

      @media screen and ( max-width: 992px ){
        background: transparent;
        box-shadow: none;
      }
    }
  }


.card{
  .card-content{
    @media screen and ( max-width: 992px ){
      background: #ffffff;
      @include border-radius(5px);
    }
  }
}

.miim-content-node-box {
  padding: 2rem;
  height: auto;
  background: white;
  @include border-radius(5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
    img:not(.upgrade-img) {
      @include round-corners(5px);
      margin-bottom: 2rem;
    }
}

.upgrade-wrapper {
  .upgrade-img {
    background-color: color(light-grey);
    height: 200px;
  }
  h3, h5, p, h1, h2 {
   text-align: center;
  }
  p {
    font-size: 14px;
  }
  h3 {
    text-transform: uppercase;
    font-size: 17px;
    &.material-ico {
      &:before {
        @extend .material-icons;
        content: 'check_circle';
        margin-right: 3px;
      }
    }
  }
  h2 {
    padding-top: 5rem;
  }
  .btn {
    margin-bottom: 5rem;
  }
  hr {
    margin-bottom: 5rem;
    border: .9px dashed black;
  }
}

.box{
  &.transparent-bg {
    background-color: rgba(255, 255, 255, 0.9);
  }
  padding: 20px 30px;
  height: auto;
  background: white;
  @include border-radius(5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
  &.error_page_box{
    padding: 80px 40px;
  }
  &.no_shadow{
    box-shadow: none;
  }

  @media screen and ( max-width: 600px ){
    padding: 20px;
  }

  form .row{
    margin-bottom: 0;
  }

  h2{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.miim-genuine-section {
  .miim-block {
    margin-bottom: 5rem;

    h3 {
      color: color(secondary);
      font-size: 1.6em;
      margin-bottom: 0rem !important;
      line-height: 24px !important;
    }
    p {
      color: grey;
    }
  }
}

.white_box {
  background-color: white;
}
