*{
  padding: 0;
  margin: 0;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

body{
  background: color(gray-background);
  main{

    min-height: 100vh;
    padding-top: 20vh;

    @media screen and ( max-width: 601px ){
      padding-top: 12vh;
    }

  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}


a{
  outline: none;
}
.float_break{
  clear: both;
}
.container{

  @media screen and ( max-width: 1201px ){
    width:90%;
  }

  @media screen and ( max-width: 903px ){
    width:90%;
  }

  @media screen and ( max-width: 601px ){
    width:90%;
  }
}


.section{
  display: block;

  &.login{

    .container{
      max-width: 700px;
    }
  }

  &.content{
    padding: 8rem 0 7rem 0;
  }

}

.item_title{
  text-transform: uppercase;
  color: #888888;
  font-weight: 600;
}

.item_data{
  color: #333333;
  font-weight: 600;
}

.mb_thin{
  margin-bottom: 20px !important;
}

.mb_large{
  margin-bottom: 34px !important;
}

.row{

  &.step{
    margin-top: 6rem;
    margin-bottom: 6rem;

    .a {
      order: 2; /* Go down, bring Box B up */
      text-align: center;
    }

    .b {
      text-align: center;
      margin-bottom: 2rem;
    }

    @media (max-width: 601px) {
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column; /* Stack on top */
      margin-top: 4rem;
    }
  }
}

.left_to_center{
  text-align: left;

  @media (max-width: 992px) {
    text-align: center;
  }
}

.col{

  &.header_large{
    width: 100%;
  }

  &.header_right{
    padding-top: 240px;

    @media screen and ( max-width: 992px ){
      padding-top: 30px;
    }
  }

  &.up_left{
    margin-top: -304px;

    @media screen and ( max-width: 992px){
      margin-top: 0;
    }
  }

  &.right_down{
    margin-top: 50px;
  }
}

#wrapper{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 50px 0 50px;
}

img{

  &.content_img{
    width: 310px;
  }

  &.title_img{
    margin: 2em 2em 2em 0;
  }
}

a.brand-logo{
  img{
    width: 128px;
  }
}

body{
  header{
    nav{
      height: 100px;
      line-height: 100px;
      box-shadow: none;
      background: transparent;
      position: absolute;

      .nav-wrapper{
        padding: 0 50px 0 50px;

        a.brand-logo{
          margin-top: 8px;
        }
      }
    }
  }

  main{
    .secondary_pages_top {
      position: absolute;
      top: 0;
      background: url('../images/top_secondary_bg.svg');
      height: 500px;
      border-radius: 0 0 50% 70%/0 0 10% 30%;
      z-index: -1;
      width: 130%;
      background-size: cover;
      @media #{$medium-and-up} {
        overflow: hidden;
        margin-bottom:-50px;
        left:-30%;
      }
      @media #{$small-and-down} {
        border-radius: 0;
        width: 100%;
      }
    }
    .section.top_content{
      background: url('../images/top13_bg.svg') no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
    .miim-standard-top-bg {
      background-color: #00000010;
      position: absolute;
      top: 0;
      width: 100%;
      height: 500px;
      background-size: cover;
      z-index: -1;
    }
  }
}


.errorMessage {
  font-size: 12px;
  color: $danger;
}
.error {
  color: $danger;
}

.toggle-password {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0.75rem;
  padding-right: 3%;

  i.material-icons{
    line-height: 50px;
    font-size: 20px;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999999;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #ee6e73;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-bg {
  position: absolute;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
}

.signIn-bottom span {
  display: inline-flex;
}
.signIn-bottom .links {
  margin-left: 5rem;

}

.lmask {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;;
  opacity: 0.4;
  &.fixed {
    position: fixed;
  }
  &:before {
    content: '';
    background-color: rgba(0,0,0,0);
    border: 5px solid rgba(0,183,229,0.9);
    opacity: .9;
    border-right: 5px solid rgba(0,0,0,0);
    border-left: 5px solid rgba(0,0,0,0);
    border-radius: 50px;
    box-shadow: 0 0 35px #2187e7;
    width: 50px;
    height: 50px;
    -moz-animation: spinPulse 1s infinite ease-in-out;
    -webkit-animation: spinPulse 1s infinite linear;

    margin: -25px 0 0 -25px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:after {
    content: '';
    background-color: rgba(0,0,0,0);
    border: 5px solid rgba(0,183,229,0.9);
    opacity: .9;
    border-left: 5px solid rgba(0,0,0,0);
    border-right: 5px solid rgba(0,0,0,0);
    border-radius: 50px;
    box-shadow: 0 0 15px #2187e7;
    width: 30px;
    height: 30px;
    -moz-animation: spinoffPulse 1s infinite linear;
    -webkit-animation: spinoffPulse 1s infinite linear;

    margin: -15px 0 0 -15px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

@-moz-keyframes spinPulse {
  0% {
    -moz-transform:rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #2187e7;
  }
  50% {
    -moz-transform: rotate(145deg);
    opacity: 1;
  }
  100% {
    -moz-transform: rotate(-320deg);
    opacity: 0;
  }
}
@-moz-keyframes spinoffPulse {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spinPulse {
  0% {
    -webkit-transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #2187e7;
  }
  50% {
    -webkit-transform: rotate(145deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-320deg);
    opacity: 0;
  }
}
@-webkit-keyframes spinoffPulse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.notification-block {
  display: block;
  padding: 1rem;
  &.errorMessage {
    background-color: #ee6e73;
  }
  &.message {
    background-color: #2187e7;
  }
    &.update {
    background-color: $dark-blue;
    a {
      color: #7ca8d4;
    }
  }
  margin: 0 !important;
  p {
    color: white;
  }
  a {
    top: 8px;
  }
}

.animate-in {
  -webkit-animation: fadeIn .5s ease-in;
  animation: fadeIn .5s ease-in;
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.miim-progress {
  .progress {
    height: 25px;
    @include border-radius(5px);
    background-color: white;
    @include box-shadow(0 1px 4px rgba(0,0,0,0.3), 0 0 40px rgba(0,0,0,0.1) inset);
    border: 2px solid white;
    .determinate {
      @include border-radius(5px);
      @include box-shadow(0 1px 4px rgba(0,0,0,0.3), 0 0 40px rgba(0,0,0,0.1) inset);
    }
  }
  .progress-profile {
    span {
      float: right;
      font-size: 1rem;
      font-weight: bold;
      color: color(secondary);
    }
  }
  .profile-completed {
    .progress-profile {
      span {
        color: #ee6e73;
      }
    }
    .determinate {
      @include box-shadow(0 1px 4px #ee6e73, 0 0 40px #ee6e73 inset);
    }
  }
}

.progress {
  &.homePage {
    position: absolute;
    top: -8px;
  }
}

.plan {
  .price-info {
    .striked {
      background: linear-gradient(to left top, transparent 47.75%, color(danger) 49.5%, color(danger) 50.5%, transparent 52.25%);
    }
    .highlight{
      font-weight: 600;
    }
  }
}

.refine-quiz {
  form {
    .row {
      margin-bottom: 1rem;
    }
  }
}

.circular--portrait {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  @media #{$small-and-down} {
    margin: 0 auto;
  }
}

.circular--portrait img {
  width: 100%;
  height: 100%;
}

.image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .3s ease;
  opacity: 1;

  .image-icon {
    color: #e0e0e0;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
}

.webkit-right {
  @media #{$medium-and-up} {
    text-align: -webkit-right !important;
    text-align: -moz-right !important;
  }
}
hr {
  border-top: 1px solid #f4f4f4;
}

.vertical-align-middle {
  vertical-align: middle;
}


.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}

.settings-tray {
  background: #eee;
  padding: 8px 15px;
  border-radius: 7px;

  .no-gutters {
    padding: 0;
  }

  &--right {
    float: right;

    i {
      margin-top: 10px;
      font-size: 25px;
      color: grey;
      margin-left: 14px;
      transition: .3s;

      &:hover {
        color: $dark-blue;
        cursor: pointer;
      }
    }
  }
}
.search-box {
  background-color: #eee;
  padding: 10px 13px;

  input {
    height: 40px;
  }

  .input-wrapper {
    background: #fff;
    border-radius: 40px;

    i {
      color: grey;
      margin-left: 7px;
      vertical-align: middle;
    }
  }
}

.search-box {
  background-color: color(gray-background);;
  padding: 10px 13px;
  margin-top: 1px;

  .input-wrapper {
    background: #fff;
    border-radius: 40px;

    i {
      color: grey;
      margin-left: 7px;
      vertical-align: middle;
    }
  }
}

input {
  border: none;
  border-radius: 30px;
  &::placeholder {
    color: #e3e3e3;
    font-weight: 300;
    margin-left: 20px;
  }

  &:focus {
    outline: none;
  }
}

.friend-drawer {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  background: #fff;
  transition: .3s ease;
  border-bottom: 1px solid #eee;
  &.active {
    border-right: 2px solid #0C2731;
  }

  &--grey {
    background: #eee;
    min-height: 70px;
  }

  .text {
    margin-left: 12px;
    width: 70%;

    h6 {
      margin-top: 6px;
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }
  }

  .time {
    color: grey;
  }

  &--onhover:hover {
    cursor: pointer;
    p,
    h6,
    .time {
    }
  }
}

.chat-box-tray {
  background: #eee;
  display: flex;
  align-items: baseline;
  padding: 10px 15px;
  align-items: center;
  bottom: 0;
  input {
    margin: 0 10px;
    padding: 6px 2px;
    background-color: white !important;
  }

  i {
    color: grey;
    font-size: 30px;
    &:last-of-type {
      margin: 0 5px 0 8px;
    }
  }
  .btn-flat.send {
    margin: 0;
    padding: 0;
  }
  .emoji-categories {
    height: 50px;
  }
}
.connexions {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  line-height: 28px !important;
  &::before {
    border-bottom: 1px dashed #ccc;
    content: "";
    flex-grow: 1;
    height: 1em;
    order: 2;
    margin: 0 3px;
  }
  div {
    &:last-child {
      order: 3;
      padding-top: 0.5em;
      font-weight: bold;
      color: black;
    }
  }
}
.chat-list {
  overflow: scroll;
  @media #{$medium-and-up} {
    height: calc(100vh - 303px)
  }
  .text {
    font-size: 12px;
  }
}
.connexions {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  &::before {
    border-bottom: 1px dashed #ccc;
    content: "";
    flex-grow: 1;
    height: 1em;
    order: 2;
    margin: 0 3px;
  }
  div {
    &:last-child {
      order: 3;
      padding-top: 0.5em;
      font-weight: bold;
      color: black;
    }
  }
}
.generic-connect {
  img {
    border-radius: 2%;
  }
  .btn-large {
    margin-top: 2rem;
  }
}

.network {
  .sort-search {
    color: gray;
    &:hover {
      cursor: pointer;
    }
    i {
      font-size: 35px;
    }
  }
}

i.material-icons {
vertical-align: middle;
position: relative;
top: -1px;
}
.dark-blue {
  &.active {
    background-color: #cccccc;
    color: $dark-blue;
  }
}


#group_add,
#mode_edit,
#message {
  overflow: visible !important;
  .badge {
    position: absolute !important;
    right: 0;
    top: -15px
  }
}
.collection {
  &.user-request {
    .request-secondary-content{
      text-align: right;
      a {
        margin-left: 5px;
      }
    }
  }
}

.box {
  &.network {
     .underline {
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
    }
  }
}
p {
  overflow-wrap: break-word;
}

.collection .collection-item.mobile {
  img {
    height: 42px;
    width: 42px;
  }
  .title {
    position: relative;
    top: -15px;
    left: 14px;
  }

  .avatar {
    min-height: 84px;
  }
}
.collection-item {
  button {
    margin-left: 5px;
  }
}

.select-wrapper{
  ul {
    padding: 0;
    li {
      list-style: none;
    }
  }
}