@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,800,900");
@import url("https://fonts.googleapis.com/css?family=Merriweather:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600");
.miim-content-node-box .title {
  margin-bottom: 5rem;
  color: #0d0a1c; }

.miim-content-node-box h3 {
  font-size: 2em; }

.miim-content-node-box li {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 28px;
  color: #585858; }

.miim-content-node-box ul {
  padding-left: 2rem; }
  .miim-content-node-box ul li {
    list-style-type: disc; }

.miim-content-node-box ol {
  padding-left: 2rem; }

a {
  color: #9e9e9e;
  text-decoration: underline;
  font-size: 1rem; }
  a.view_more {
    display: block;
    margin-top: 16px;
    color: #26A69A; }

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 28px;
  color: #585858; }

.pad.left-align p,
.pad.left-align h2,
.pad.left-align h3 {
  text-align: left; }

.pad.left-align ul {
  margin-top: 20%; }
  @media screen and (max-width: 786px) {
    .pad.left-align ul {
      margin-top: 10%; } }
  @media (max-width: 601px) {
    .pad.left-align ul {
      margin-top: 0; } }
  .pad.left-align ul li {
    font-family: 'Merriweather', serif;
    font-size: 28px;
    line-height: 40px;
    font-weight: 300;
    color: #0C2731;
    list-style-type: none;
    margin-bottom: 1rem;
    text-align: left; }
    @media screen and (max-width: 1114px) {
      .pad.left-align ul li {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 1.4rem; } }

.pad.right-align p,
.pad.right-align h2,
.pad.right-align h3 {
  text-align: right; }

.pad.right-align ul {
  margin-top: 20%; }
  @media screen and (max-width: 786px) {
    .pad.right-align ul {
      margin-top: 10%; } }
  @media (max-width: 601px) {
    .pad.right-align ul {
      margin-top: 0; } }
  .pad.right-align ul li {
    font-family: 'Merriweather', serif;
    font-size: 28px;
    line-height: 40px;
    font-weight: 300;
    color: #0C2731;
    list-style-type: none;
    display: inline-flex;
    margin-bottom: 1rem;
    max-width: 80%;
    text-align: right; }
    @media screen and (max-width: 1114px) {
      .pad.right-align ul li {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 1.4rem; } }

h1 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 36px;
  color: #ffffff; }
  h1.error_page_title {
    text-transform: none;
    margin-bottom: 60px; }
  h1.error_page_number {
    font-size: 200px;
    margin-bottom: 120px; }
    @media screen and (max-width: 601px) {
      h1.error_page_number {
        font-size: 40vw; } }
  h1.page_title.profile_title {
    margin-top: 200px;
    margin-bottom: 0; }
    @media screen and (max-width: 992px) {
      h1.page_title.profile_title {
        margin-top: 100px; } }
  @media screen and (max-width: 992px) {
    h1.page_title {
      font-size: 32px !important;
      line-height: 40px !important;
      font-weight: 600 !important; } }
  h1.account_page span {
    font-weight: lighter;
    color: #541E69;
    font-size: 2rem; }
  @media screen and (max-width: 992px) {
    h1.account_page {
      text-align: center; } }
  h1.baseline {
    text-align: center;
    font-size: 38px;
    line-height: 56px; }
    @media only screen and (max-width: 600px) {
      h1.baseline {
        font-size: 32px; } }

h2 {
  font-family: 'Merriweather', serif;
  font-size: 54px;
  line-height: 70px;
  font-weight: 300;
  display: block;
  margin: 0 auto;
  margin-bottom: 3rem;
  color: #0C2731; }
  @media screen and (max-width: 992px) {
    h2 {
      font-size: 50px;
      line-height: 64px;
      margin-bottom: 2rem; } }

h3 {
  font-family: 'Merriweather', serif;
  font-size: 34px;
  font-weight: 300;
  color: #0C2731;
  margin-bottom: 2rem; }
  h3.thin {
    font-size: 26px;
    line-height: 40px;
    font-weight: 100;
    margin-top: 12px;
    margin-bottom: 1.6rem;
    color: #0C2731; }
    @media screen and (max-width: 992px) {
      h3.thin {
        font-size: 22px !important; } }
  h3 span.injunction {
    font-size: 16px; }
  h3.fixed_height {
    height: 80px; }

h4 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 1.5rem;
  color: #444444;
  padding: 0 0 0 1rem; }

.upgrade-wrapper h1 {
  color: black; }

.material-icons, .upgrade-wrapper h3.material-ico:before {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons', serif;
  font-size: 18px;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

.divider {
  background-color: #ebebeb;
  margin: 30px 0 30px 0; }

.input-field > input[type]:-webkit-autofill:not(.browser-default):not([type="search"]) + label,
.input-field > input[type=date]:not(.browser-default) + label, .input-field > input[type=time]:not(.browser-default) + label {
  -webkit-transform: translateY(-22px) scale(0.8); }

.datepicker-container input:not([type]),
.datepicker-container input[type=text]:not(.browser-default),
.datepicker-container input[type=password]:not(.browser-default),
.datepicker-container input[type=email]:not(.browser-default),
.datepicker-container input[type=url]:not(.browser-default),
.datepicker-container input[type=time]:not(.browser-default),
.datepicker-container input[type=date]:not(.browser-default),
.datepicker-container input[type=datetime]:not(.browser-default),
.datepicker-container input[type=datetime-local]:not(.browser-default),
.datepicker-container input[type=tel]:not(.browser-default),
.datepicker-container input[type=number]:not(.browser-default),
.datepicker-container input[type=search]:not(.browser-default),
.datepicker-container textarea.materialize-textarea {
  background-color: inherit; }

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  background-color: rgba(235, 235, 235, 0.7);
  color: #5c5c5c;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 92%;
  padding: 0 4% 0 4%;
  line-height: 50px;
  height: 50px; }
  input:not([type]):focus,
  input[type=text]:not(.browser-default):focus,
  input[type=password]:not(.browser-default):focus,
  input[type=email]:not(.browser-default):focus,
  input[type=url]:not(.browser-default):focus,
  input[type=time]:not(.browser-default):focus,
  input[type=date]:not(.browser-default):focus,
  input[type=datetime]:not(.browser-default):focus,
  input[type=datetime-local]:not(.browser-default):focus,
  input[type=tel]:not(.browser-default):focus,
  input[type=number]:not(.browser-default):focus,
  input[type=search]:not(.browser-default):focus,
  textarea.materialize-textarea:focus {
    border: 1px solid #26A69A;
    box-shadow: none !important; }
  input:not([type]).invalid,
  input[type=text]:not(.browser-default).invalid,
  input[type=password]:not(.browser-default).invalid,
  input[type=email]:not(.browser-default).invalid,
  input[type=url]:not(.browser-default).invalid,
  input[type=time]:not(.browser-default).invalid,
  input[type=date]:not(.browser-default).invalid,
  input[type=datetime]:not(.browser-default).invalid,
  input[type=datetime-local]:not(.browser-default).invalid,
  input[type=tel]:not(.browser-default).invalid,
  input[type=number]:not(.browser-default).invalid,
  input[type=search]:not(.browser-default).invalid,
  textarea.materialize-textarea.invalid {
    border: 1px solid #ee6e73;
    box-shadow: none; }

.input-field > label:not(.label-icon).active,
input[type="text"]:not(.browser-default).validate + label,
input[type="password"]:not(.browser-default).validate + label,
input[type="email"]:not(.browser-default).validate + label,
.input-field > label {
  -webkit-transform: translateY(-22px) scale(0.8);
  transform: translateY(-22px) scale(0.8);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }
  .input-field > label:not(.label-icon).active.select_label,
  input[type="text"]:not(.browser-default).validate + label.select_label,
  input[type="password"]:not(.browser-default).validate + label.select_label,
  input[type="email"]:not(.browser-default).validate + label.select_label,
  .input-field > label.select_label {
    -webkit-transform: translateY(4px) scale(1);
    transform: translateY(4px) scale(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }

.input-field.col label {
  padding-left: 1%;
  color: #BFBFBF; }

select option .disabled {
  color: #5c5c5c; }

/* Placeholder */
::-webkit-input-placeholder {
  color: #5c5c5c; }

:-moz-placeholder {
  color: #5c5c5c;
  opacity: 1; }

::-moz-placeholder {
  color: #5c5c5c;
  opacity: 1; }

:-ms-input-placeholder {
  color: #5c5c5c; }

::-ms-input-placeholder {
  color: #5c5c5c; }

::placeholder {
  color: #5c5c5c; }

.select-wrapper .caret {
  right: 3%;
  fill: black; }

.white_field input[type=text]:not(.browser-default) {
  background-color: rgba(255, 255, 255, 0.7); }

.white_field textarea {
  background-color: #ffffff;
  height: auto;
  line-height: 1.8rem;
  padding: 20px 20px;
  width: 100%; }

.form_footer ul {
  font-size: 1rem; }
  .form_footer ul li {
    list-style-type: none; }
    .form_footer ul li label span {
      line-height: normal !important;
      height: auto !important; }
      @media screen and (max-width: 400px) {
        .form_footer ul li label span {
          padding-left: 22px !important; } }
      .form_footer ul li label span::after {
        top: 50% !important;
        margin-top: -10px !important; }
        @media screen and (max-width: 400px) {
          .form_footer ul li label span::after {
            margin-top: -8px !important;
            height: 16px !important;
            width: 16px !important; } }

.is-safari .miim-register-terms-conditions-block {
  margin-top: 1rem; }
  .is-safari .miim-register-terms-conditions-block ul {
    margin: 0; }

.miim-register-terms-conditions-block ul,
.login ul {
  display: inline-table; }

.miim-register-terms-conditions-block a,
.login a {
  margin-left: 4px; }

[type=radio] + img {
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 5px;
  border: solid 1px #F4F4F4;
  -moz-box-shadow: 1px 1px 5px #999;
  -webkit-box-shadow: 1px 1px 5px #999;
  box-shadow: 1px 1px 5px #999; }

[type=radio]:checked + img {
  border: solid 2px #0C2731; }

.network .materialize-textarea {
  min-height: 200px;
  width: 100%;
  line-height: 26px; }

.btn {
  color: #ffffff;
  padding: 0 20px 0 20px;
  border-radius: 30px; }
  .btn.full {
    width: 100%; }
  .btn.dark-blue {
    background-color: #0C2731; }
  .btn.btn-flat {
    color: #541E69; }
  .btn.btn-stroke {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff; }
    .btn.btn-stroke:hover {
      background: #ffffff;
      color: #541E69; }

.btn-large {
  padding: 0 40px 0 40px;
  border-radius: 30px;
  font-weight: bold;
  color: #ffffff;
  background-color: #26A69A; }
  .btn-large.dark-blue {
    background-color: #0C2731; }
  .btn-large.call_to_action:hover {
    background-color: #fff;
    color: #541E69;
    box-shadow: none; }
  @media screen and (max-width: 902px) {
    .btn-large.call_to_action {
      margin: 0 auto; } }
  .btn-large.submit {
    background-color: #26A69A;
    margin: 20px 0 20px 0; }
    .btn-large.submit:hover {
      background-color: #541E69;
      box-shadow: none; }

.quiz-profileActions a {
  margin-bottom: 2rem;
  width: 100%; }
  .quiz-profileActions a.active {
    background-color: #0C2731; }

.btn-group a {
  margin-bottom: 1rem; }

button {
  background-color: transparent;
  border: none; }
  button:hover {
    cursor: pointer; }
  button:focus {
    background-color: transparent; }

.cards-container {
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px; }
  @media only screen and (max-width: 600px) {
    .cards-container {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1; } }
  @media only screen and (min-width: 601px) {
    .cards-container {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2; } }
  @media only screen and (min-width: 993px) {
    .cards-container {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3; } }

.card {
  display: inline-block;
  overflow: visible;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 3px 1px -2px rgba(0, 0, 0, 0.02), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px; }
  .card .card-image i {
    background-color: #26A69A;
    color: rgba(255, 255, 255, 0.5);
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 3em;
    font-size: 5em; }
  .card .card-image img {
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    @media screen and (max-width: 992px) {
      .card .card-image img {
        margin: 0 auto;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%; } }
  @media screen and (max-width: 992px) {
    .card .card-content {
      background: #ffffff;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px; } }
  .card.box_like {
    width: 100%;
    margin: 0 0 0 0;
    height: auto;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); }
    @media screen and (max-width: 992px) {
      .card.box_like {
        background: transparent;
        box-shadow: none; } }

@media screen and (max-width: 992px) {
  .card .card-content {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; } }

.miim-content-node-box {
  padding: 2rem;
  height: auto;
  background: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); }
  .miim-content-node-box img:not(.upgrade-img) {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin-bottom: 2rem; }

.upgrade-wrapper .upgrade-img {
  background-color: #ebebeb;
  height: 200px; }

.upgrade-wrapper h3, .upgrade-wrapper h5, .upgrade-wrapper p, .upgrade-wrapper h1, .upgrade-wrapper h2 {
  text-align: center; }

.upgrade-wrapper p {
  font-size: 14px; }

.upgrade-wrapper h3 {
  text-transform: uppercase;
  font-size: 17px; }
  .upgrade-wrapper h3.material-ico:before {
    content: 'check_circle';
    margin-right: 3px; }

.upgrade-wrapper h2 {
  padding-top: 5rem; }

.upgrade-wrapper .btn {
  margin-bottom: 5rem; }

.upgrade-wrapper hr {
  margin-bottom: 5rem;
  border: .9px dashed black; }

.box {
  padding: 20px 30px;
  height: auto;
  background: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); }
  .box.transparent-bg {
    background-color: rgba(255, 255, 255, 0.9); }
  .box.error_page_box {
    padding: 80px 40px; }
  .box.no_shadow {
    box-shadow: none; }
  @media screen and (max-width: 600px) {
    .box {
      padding: 20px; } }
  .box form .row {
    margin-bottom: 0; }
  .box h2 {
    margin-top: 1rem;
    margin-bottom: 1rem; }

.miim-genuine-section .miim-block {
  margin-bottom: 5rem; }
  .miim-genuine-section .miim-block h3 {
    color: #26A69A;
    font-size: 1.6em;
    margin-bottom: 0rem !important;
    line-height: 24px !important; }
  .miim-genuine-section .miim-block p {
    color: grey; }

.white_box {
  background-color: white; }

footer.page-footer {
  background-color: #0C2731; }
  footer.page-footer .brand-logo img {
    width: 128px; }
  footer.page-footer p,
  footer.page-footer a {
    color: rgba(255, 255, 255, 0.5); }
  footer.page-footer .container {
    padding: 100px 0 100px 0; }
  footer.page-footer h5 {
    margin-bottom: 30px; }
  footer.page-footer ul.footer_right_menu li {
    margin-bottom: 10px; }
  footer.page-footer ul.footer_social_menu li {
    display: inline;
    margin-left: 8px; }
  footer.page-footer .footer-copyright {
    font-size: 12px;
    background-color: #081c24; }
    footer.page-footer .footer-copyright .container {
      padding: 20px 0 20px 0; }
  footer.page-footer .right {
    float: none !important; }

.navbar-fixed {
  position: fixed;
  width: 100%; }
  .navbar-fixed.dark .nav-wrapper {
    background-color: white; }
    .navbar-fixed.dark .nav-wrapper li, .navbar-fixed.dark .nav-wrapper a {
      color: #707070; }
      .navbar-fixed.dark .nav-wrapper li.active, .navbar-fixed.dark .nav-wrapper a.active {
        color: black;
        font-weight: bold; }
  .navbar-fixed.dark .brand-logo .svg {
    filter: invert(10%) sepia(45%) saturate(951%) hue-rotate(152deg) brightness(92%) contrast(92%); }
  .navbar-fixed.dark nav {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1); }
    .navbar-fixed.dark nav ul li a:hover, .navbar-fixed.dark nav ul li a.active {
      color: black;
      transition: 0.3s ease-out; }
  .navbar-fixed.dark .sidenav-trigger {
    color: black; }

nav {
  height: 110px;
  line-height: 110px;
  box-shadow: none;
  background-color: rgba(12, 39, 49, 0);
  border: none;
  color: #fff;
  transition: height 0.3s ease-out, line-height 0.3s ease-out, background-color 0.3s ease-out; }
  nav .dropdown-content.spthinx a {
    height: 66px !important; }
  nav .dropdown-content.spthinx .btn {
    height: 35px !important;
    color: white; }
    nav .dropdown-content.spthinx .btn:hover {
      background-color: #541E69; }
  nav .brand-logo .svg {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(104%); }
  nav a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase; }
    nav a i {
      height: 110px;
      line-height: 110px; }
    nav a:hover {
      background: transparent !important; }
    nav a.sidenav-trigger i {
      height: 110px;
      line-height: 110px;
      transition: 0.3s ease-out;
      font-size: 32px; }
  nav .nav-wrapper {
    padding: 0 50px 0 50px; }
    @media screen and (max-width: 992px) {
      nav .nav-wrapper {
        padding: 0 5px 0 5px !important; } }
    nav .nav-wrapper .brand-logo {
      margin-top: 8px;
      left: 5%;
      -webkit-transform: none;
      transform: none; }
      nav .nav-wrapper .brand-logo img {
        width: 80px; }
    nav .nav-wrapper ul.main_menu li a {
      height: 110px; }
      nav .nav-wrapper ul.main_menu li a.login_register_items {
        padding: 0 8px; }
      nav .nav-wrapper ul.main_menu li a img.profile-img {
        width: 48px;
        height: 48px;
        margin-top: calc((110px - 48px) / 2);
        transition: 0.3s ease-out;
        top: -3px;
        position: relative; }
    nav .nav-wrapper ul.main_menu li ul.dropdown-content {
      width: auto !important; }
      nav .nav-wrapper ul.main_menu li ul.dropdown-content li a {
        height: auto; }
  nav.scroll {
    background-color: #fff;
    color: #0C2731;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    height: 66px;
    line-height: 66px; }
    nav.scroll .brand-logo .svg {
      filter: invert(10%) sepia(45%) saturate(951%) hue-rotate(152deg) brightness(92%) contrast(92%); }
    nav.scroll a {
      text-decoration: none;
      color: #0C2731; }
      nav.scroll a i {
        height: 66px;
        line-height: 66px; }
      nav.scroll a.sidenav-trigger i {
        height: 66px;
        line-height: 66px;
        transition: 0.3s ease-out; }
    nav.scroll .nav-wrapper {
      padding: 0 50px 0 50px; }
      @media screen and (max-width: 902px) {
        nav.scroll .nav-wrapper {
          padding: 0 5px 0 5px !important; } }
      nav.scroll .nav-wrapper .brand-logo {
        margin-top: 8px;
        left: 5%;
        -webkit-transform: none;
        transform: none; }
        nav.scroll .nav-wrapper .brand-logo img {
          width: 80px; }
      nav.scroll .nav-wrapper ul.main_menu li a {
        height: 66px; }
        nav.scroll .nav-wrapper ul.main_menu li a.login_register_items {
          padding: 0 8px; }
        nav.scroll .nav-wrapper ul.main_menu li a img.profile-img {
          width: 48px;
          height: 48px;
          margin-top: calc((66px - 48px) / 2); }

.sidenav a {
  text-decoration: none; }
  .sidenav a.active {
    color: black;
    font-weight: bold; }

.sidenav-overlay {
  z-index: 996; }

.miim-mobile-sidebar .collapsible .collapsible-header {
  padding: 0 32px; }

.miim-mobile-sidebar .collapsible .collapsible-body a {
  display: block;
  padding: 0 32px; }

.miim-mobile-sidebar a, .miim-mobile-sidebar .collapsible-header {
  text-transform: uppercase; }

.logout i {
  font-size: 16px; }

.sidenav i {
  margin: 0 !important; }

#spthinx li:hover {
  background-color: transparent !important; }

#spthinx li a {
  text-transform: capitalize; }

.arrow-down-after:after {
  font-family: 'Material Icons', serif;
  content: "\e5c5"; }

.btn.social > :first-child,
.btn-large.social > :first-child,
.col.social > :first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.2); }

.btn.social,
.btn-large.social,
.col.social {
  padding: 0 2rem 0 0; }

.btn.social i,
.btn-large.social i,
.col.social i {
  padding: 0 1rem;
  margin-right: 1rem; }

.btn.social-icon,
.btn-large.social-icon,
.col.social-icon {
  padding: 0; }

.btn.social-icon i,
.btn-large.social-icon i,
.col.social-icon i {
  padding: 0 1rem;
  margin-right: 0; }

.btn-large.social-icon {
  padding: 0 1rem; }

.col.social {
  width: 100%;
  text-align: left; }

.col.social i.fa-fw {
  width: 3.2rem; }

.adn {
  background-color: #d87a68;
  color: #fff !important; }

.adn i {
  color: #fff !important; }

.adn:hover {
  background-color: #e29e91 !important; }

.bitbucket {
  background-color: #205081;
  color: #fff !important; }

.bitbucket i {
  color: #fff !important; }

.bitbucket:hover {
  background-color: #2a69aa !important; }

.dropbox {
  background-color: #1087dd;
  color: #fff !important; }

.dropbox i {
  color: #fff !important; }

.dropbox:hover {
  background-color: #309ff0 !important; }

.facebook {
  background-color: #3b5998;
  color: #fff !important; }

.facebook i {
  color: #fff !important; }

.facebook:hover {
  background-color: #4c70ba !important; }

.flickr {
  background-color: #ff0084;
  color: #fff !important; }

.flickr i {
  color: #fff !important; }

.flickr:hover {
  background-color: #ff339d !important; }

.foursquare {
  background-color: #f94877;
  color: #fff !important; }

.foursquare i {
  color: #fff !important; }

.foursquare:hover {
  background-color: #fb799c !important; }

.github {
  background-color: #444444;
  color: #fff !important; }

.github i {
  color: #fff !important; }

.github:hover {
  background-color: #5e5e5e !important; }

.google {
  background-color: #dd4b39;
  color: #fff !important; }

.google i {
  color: #fff !important; }

.google:hover {
  background-color: #e47365 !important; }

.instagram {
  background-color: #3f729b;
  color: #fff !important; }

.instagram i {
  color: #fff !important; }

.instagram:hover {
  background-color: #548cb9 !important; }

.linkedin {
  background-color: #007bb6;
  color: #fff !important; }

.linkedin i {
  color: #fff !important; }

.linkedin:hover {
  background-color: #009de9 !important; }

.microsoft {
  background-color: #2672ec;
  color: #fff !important; }

.microsoft i {
  color: #fff !important; }

.microsoft:hover {
  background-color: #5590f0 !important; }

.windows {
  background-color: #2672ec;
  color: #fff !important; }

.windows i {
  color: #fff !important; }

.windows:hover {
  background-color: #5590f0 !important; }

.odnoklassniki {
  background-color: #f4731c;
  color: #fff !important; }

.odnoklassniki i {
  color: #fff !important; }

.odnoklassniki:hover {
  background-color: #f6914d !important; }

.openid {
  background-color: #f7931e;
  color: #fff !important; }

.openid i {
  color: #fff !important; }

.openid:hover {
  background-color: #f9ab4f !important; }

.pinterest {
  background-color: #cb2027;
  color: #fff !important; }

.pinterest i {
  color: #fff !important; }

.pinterest:hover {
  background-color: #e03e44 !important; }

.reddit {
  background-color: #eff7ff;
  color: #000 !important; }

.reddit i {
  color: #000 !important; }

.reddit:hover {
  background-color: #ffffff !important; }

.soundcloud {
  background-color: #ff5500;
  color: #fff !important; }

.soundcloud i {
  color: #fff !important; }

.soundcloud:hover {
  background-color: #ff7733 !important; }

.tumblr {
  background-color: #2c4762;
  color: #fff !important; }

.tumblr i {
  color: #fff !important; }

.tumblr:hover {
  background-color: #3c6085 !important; }

.twitter {
  background-color: #55acee;
  color: #fff !important; }

.twitter i {
  color: #fff !important; }

.twitter:hover {
  background-color: #83c3f3 !important; }

.vimeo {
  background-color: #1ab7ea;
  color: #fff !important; }

.vimeo i {
  color: #fff !important; }

.vimeo:hover {
  background-color: #49c6ee !important; }

.vk {
  background-color: #587ea3;
  color: #fff !important; }

.vk i {
  color: #fff !important; }

.vk:hover {
  background-color: #7897b6 !important; }

.yahoo {
  background-color: #720e9e;
  color: #fff !important; }

.yahoo i {
  color: #fff !important; }

.yahoo:hover {
  background-color: #9412cd !important; }

.top_banner {
  background: url("../images/top15_bg.svg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin-top: -20vh; }
  @media screen and (max-width: 601px) {
    .top_banner {
      margin-top: -12vh; } }
  .top_banner .container {
    height: calc(70vh + 180px);
    padding-top: 20vh; }
    @media screen and (max-width: 601px) {
      .top_banner .container {
        padding-top: 6vh; } }
    .top_banner .container .wrapper_call_to_action .miim-into p:first-child {
      color: red;
      position: relative;
      line-height: 28px;
      color: #26A69A;
      font-size: 1.8rem;
      font-weight: bold;
      padding-bottom: 2rem; }
    .top_banner .container .wrapper_call_to_action .miim-into p {
      color: white;
      font-family: 'Montserrat', sans-serif; }

.logged_in main {
  padding-top: 150px; }
  @media screen and (max-width: 601px) {
    .logged_in main {
      padding-top: 80px; } }

.logged_in nav {
  background-color: white;
  color: #707070; }
  @media screen and (max-width: 992px) {
    .logged_in nav {
      background-color: white; } }
  @media screen and (max-width: 992px) {
    .logged_in nav.scroll {
      background-color: white; } }
  .logged_in nav .brand-logo .svg {
    filter: invert(10%) sepia(45%) saturate(951%) hue-rotate(152deg) brightness(92%) contrast(92%); }
  .logged_in nav a {
    color: #707070; }
  .logged_in nav .brand-logo {
    margin-top: 8px;
    left: 5%;
    -webkit-transform: none;
    transform: none; }
    .logged_in nav .brand-logo img {
      width: 80px;
      fill: red; }

.logged_in .header-profile-01-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-01-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-02-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-02-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-03-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-03-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-04-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-04-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-05-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-05-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-06-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-06-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-07-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-07-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-08-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-08-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-09-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-09-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-10-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-10-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-11-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-11-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-12-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-12-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-profile-13-closed.profile_bg {
  background: url("../images/profile-bg/header-profile-13-closed.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-behavior-01-setting.spthinx_bg {
  background: url("../images/riddle/header-behavior-01-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-behavior-02-setting.spthinx_bg {
  background: url("../images/riddle/header-behavior-02-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-behavior-03-setting.spthinx_bg {
  background: url("../images/riddle/header-behavior-03-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-behavior-04-setting.spthinx_bg {
  background: url("../images/riddle/header-behavior-04-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-behavior-05-setting.spthinx_bg {
  background: url("../images/riddle/header-behavior-05-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-01-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-01-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-02-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-02-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-03-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-03-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-04-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-04-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-05-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-05-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-06-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-06-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-07-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-07-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-08-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-08-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-09-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-09-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-10-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-10-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-11-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-11-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-12-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-12-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-knack-13-setting.spthinx_bg {
  background: url("../images/riddle/header-knack-13-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relational-pattern-01-setting.spthinx_bg {
  background: url("../images/riddle/header-relational-pattern-01-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relational-pattern-02-setting.spthinx_bg {
  background: url("../images/riddle/header-relational-pattern-02-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relational-pattern-03-setting.spthinx_bg {
  background: url("../images/riddle/header-relational-pattern-03-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relational-pattern-04-setting.spthinx_bg {
  background: url("../images/riddle/header-relational-pattern-04-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relationship-01-setting.spthinx_bg {
  background: url("../images/riddle/header-relationship-01-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relationship-02-setting.spthinx_bg {
  background: url("../images/riddle/header-relationship-02-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relationship-03-setting.spthinx_bg {
  background: url("../images/riddle/header-relationship-03-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relationship-04-setting.spthinx_bg {
  background: url("../images/riddle/header-relationship-04-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-relationship-05-setting.spthinx_bg {
  background: url("../images/riddle/header-relationship-05-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-01-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-01-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-02-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-02-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-03-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-03-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-04-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-04-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-05-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-05-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-06-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-06-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-07-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-07-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-08-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-08-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-09-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-09-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-10-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-10-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-11-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-11-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-12-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-12-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-stress-13-setting.spthinx_bg {
  background: url("../images/riddle/header-stress-13-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-weakness-01-setting.spthinx_bg {
  background: url("../images/riddle/header-weakness-01-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-weakness-02-setting.spthinx_bg {
  background: url("../images/riddle/header-weakness-02-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-weakness-03-setting.spthinx_bg {
  background: url("../images/riddle/header-weakness-03-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-weakness-04-setting.spthinx_bg {
  background: url("../images/riddle/header-weakness-04-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-weakness-05-setting.spthinx_bg {
  background: url("../images/riddle/header-weakness-05-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-weakness-06-setting.spthinx_bg {
  background: url("../images/riddle/header-weakness-06-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-weakness-07-setting.spthinx_bg {
  background: url("../images/riddle/header-weakness-07-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-weakness-08-setting.spthinx_bg {
  background: url("../images/riddle/header-weakness-08-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-01-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-01-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-02-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-02-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-03-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-03-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-04-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-04-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-05-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-05-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-06-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-06-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-07-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-07-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-08-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-08-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-09-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-09-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-10-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-10-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-11-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-11-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-12-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-12-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-13-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-13-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .header-cognition-14-setting.spthinx_bg {
  background: url("../images/riddle/header-cognition-14-setting.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.15); }

.logged_in .profile_bg,
.logged_in .spthinx_bg {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 600px;
  z-index: -1; }
  @media screen and (max-width: 992px) {
    .logged_in .profile_bg,
    .logged_in .spthinx_bg {
      min-height: 420px; } }

.logged_in h1.page_title {
  color: #0C2731; }

.pagination {
  display: flex;
  align-items: stretch;
  justify-content: center; }
  .pagination.stepper-quiz {
    justify-content: space-between;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0; }
  .pagination.stepper-refine {
    justify-content: space-between; }
  @media screen and (max-width: 601px) {
    .pagination {
      margin: 40px 0 20px 0; } }
  .pagination.mobile._6 li:nth-child(1), .pagination.mobile._6 li:nth-child(2), .pagination.mobile._7 li:nth-child(1), .pagination.mobile._7 li:nth-child(2), .pagination.mobile._8 li:nth-child(1), .pagination.mobile._8 li:nth-child(2), .pagination.mobile._9 li:nth-child(1), .pagination.mobile._9 li:nth-child(2), .pagination.mobile._10 li:nth-child(1), .pagination.mobile._10 li:nth-child(2) {
    display: none; }
  .pagination li {
    display: block;
    flex: 0 1 auto;
    height: auto;
    width: auto;
    position: relative; }
    .pagination li.active {
      background-color: #ccc;
      border-radius: 50%; }
    .pagination li a {
      text-decoration: none; }
    .pagination li.pulse {
      z-index: 9999; }
    .pagination li .btn-large {
      box-shadow: none;
      line-height: 3rem; }
      .pagination li .btn-large.grey {
        background-color: #e9e8e8 !important;
        color: #ccc;
        z-index: 100; }
    .pagination li .btn-small {
      box-shadow: none;
      width: 40px;
      height: 40px;
      line-height: 40px; }
      .pagination li .btn-small i {
        font-size: 1.4rem;
        width: auto; }
      .pagination li .btn-small.grey {
        background-color: #e9e8e8 !important;
        color: #ccc;
        z-index: 100; }
    .pagination li span {
      display: block;
      color: #9c9b9b; }
    .pagination li .active {
      font-weight: bold;
      font-size: 2em;
      color: #26A69A; }

.stepper-refine {
  width: 50%; }
  .stepper-refine.mobile {
    width: 100%; }

input[type="range"]::-moz-focus-inner {
  border: 0; }

@media only screen and (max-width: 992px) {
  input[type="range"]::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    margin-top: -8px; } }

@media only screen and (max-width: 992px) {
  input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    margin-top: -8px; } }

@media only screen and (max-width: 992px) {
  input[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    margin-top: -8px; } }

input[type="range"] + .thumb .value {
  display: block;
  width: 30px;
  text-align: center;
  color: #fff;
  font-size: 14px !important;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

input[type="range"] + .thumb.active .value {
  margin-left: 0px;
  margin-top: 0px; }

input[type="range"] + .thumb .value {
  display: block;
  width: 30px;
  text-align: center;
  color: #fff;
  font-size: 14px !important;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

input[type="range"] + .thumb.active .value {
  margin-left: 0px;
  margin-top: 0px; }

.semi-donut {
  --percentage: 0;
  --fill: #AB2171;
  width: 300px;
  height: 150px;
  position: relative;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box; }
  .semi-donut:after {
    content: '';
    width: 300px;
    height: 300px;
    border: 50px solid;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) var(--fill) var(--fill);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    transform: rotate(calc( 1deg * ( -45 + var(--percentage) * 1.8 )));
    animation: fillAnimation 1s ease-in; }

.match td {
  background-repeat: no-repeat;
  transition-delay: 1.2s; }

.match td:nth-child(2) {
  width: 30%;
  text-align: right;
  background-image: linear-gradient(to left, #E5E5E5, #E5E5E5);
  background-position: right top; }

.match td:last-child {
  width: 30%;
  background-image: linear-gradient(to right, #E5E5E5, #E5E5E5);
  background-position: left top; }

.match .cellDifference {
  background-image: linear-gradient(to left, #CA377E, #CA377E) !important;
  color: white; }

.match .cellSimilarity {
  background-image: linear-gradient(to left, #26A69A, #26A69A) !important;
  color: white; }

* {
  padding: 0;
  margin: 0; }

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif; }

body {
  background: #F4F4F4; }
  body main {
    min-height: 100vh;
    padding-top: 20vh; }
    @media screen and (max-width: 601px) {
      body main {
        padding-top: 12vh; } }
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px; }
  body ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

a {
  outline: none; }

.float_break {
  clear: both; }

@media screen and (max-width: 1201px) {
  .container {
    width: 90%; } }

@media screen and (max-width: 903px) {
  .container {
    width: 90%; } }

@media screen and (max-width: 601px) {
  .container {
    width: 90%; } }

.section {
  display: block; }
  .section.login .container {
    max-width: 700px; }
  .section.content {
    padding: 8rem 0 7rem 0; }

.item_title {
  text-transform: uppercase;
  color: #888888;
  font-weight: 600; }

.item_data {
  color: #333333;
  font-weight: 600; }

.mb_thin {
  margin-bottom: 20px !important; }

.mb_large {
  margin-bottom: 34px !important; }

.row.step {
  margin-top: 6rem;
  margin-bottom: 6rem; }
  .row.step .a {
    order: 2;
    /* Go down, bring Box B up */
    text-align: center; }
  .row.step .b {
    text-align: center;
    margin-bottom: 2rem; }
  @media (max-width: 601px) {
    .row.step {
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
      /* Stack on top */
      margin-top: 4rem; } }

.left_to_center {
  text-align: left; }
  @media (max-width: 992px) {
    .left_to_center {
      text-align: center; } }

.col.header_large {
  width: 100%; }

.col.header_right {
  padding-top: 240px; }
  @media screen and (max-width: 992px) {
    .col.header_right {
      padding-top: 30px; } }

.col.up_left {
  margin-top: -304px; }
  @media screen and (max-width: 992px) {
    .col.up_left {
      margin-top: 0; } }

.col.right_down {
  margin-top: 50px; }

#wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 50px 0 50px; }

img.content_img {
  width: 310px; }

img.title_img {
  margin: 2em 2em 2em 0; }

a.brand-logo img {
  width: 128px; }

body header nav {
  height: 100px;
  line-height: 100px;
  box-shadow: none;
  background: transparent;
  position: absolute; }
  body header nav .nav-wrapper {
    padding: 0 50px 0 50px; }
    body header nav .nav-wrapper a.brand-logo {
      margin-top: 8px; }

body main .secondary_pages_top {
  position: absolute;
  top: 0;
  background: url("../images/top_secondary_bg.svg");
  height: 500px;
  border-radius: 0 0 50% 70%/0 0 10% 30%;
  z-index: -1;
  width: 130%;
  background-size: cover; }
  @media only screen and (min-width: 601px) {
    body main .secondary_pages_top {
      overflow: hidden;
      margin-bottom: -50px;
      left: -30%; } }
  @media only screen and (max-width: 600px) {
    body main .secondary_pages_top {
      border-radius: 0;
      width: 100%; } }

body main .section.top_content {
  background: url("../images/top13_bg.svg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%; }

body main .miim-standard-top-bg {
  background-color: #00000010;
  position: absolute;
  top: 0;
  width: 100%;
  height: 500px;
  background-size: cover;
  z-index: -1; }

.errorMessage {
  font-size: 12px;
  color: #ee6e73; }

.error {
  color: #ee6e73; }

.toggle-password {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0.75rem;
  padding-right: 3%; }
  .toggle-password i.material-icons {
    line-height: 50px;
    font-size: 20px; }

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999999;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #ee6e73;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }

.loader-bg {
  position: absolute;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%; }

.signIn-bottom span {
  display: inline-flex; }

.signIn-bottom .links {
  margin-left: 5rem; }

.lmask {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.4; }
  .lmask.fixed {
    position: fixed; }
  .lmask:before {
    content: '';
    background-color: rgba(0, 0, 0, 0);
    border: 5px solid rgba(0, 183, 229, 0.9);
    opacity: .9;
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
    box-shadow: 0 0 35px #2187e7;
    width: 50px;
    height: 50px;
    -moz-animation: spinPulse 1s infinite ease-in-out;
    -webkit-animation: spinPulse 1s infinite linear;
    margin: -25px 0 0 -25px;
    position: absolute;
    top: 50%;
    left: 50%; }
  .lmask:after {
    content: '';
    background-color: rgba(0, 0, 0, 0);
    border: 5px solid rgba(0, 183, 229, 0.9);
    opacity: .9;
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
    box-shadow: 0 0 15px #2187e7;
    width: 30px;
    height: 30px;
    -moz-animation: spinoffPulse 1s infinite linear;
    -webkit-animation: spinoffPulse 1s infinite linear;
    margin: -15px 0 0 -15px;
    position: absolute;
    top: 50%;
    left: 50%; }

@-moz-keyframes spinPulse {
  0% {
    -moz-transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #2187e7; }
  50% {
    -moz-transform: rotate(145deg);
    opacity: 1; }
  100% {
    -moz-transform: rotate(-320deg);
    opacity: 0; } }

@-moz-keyframes spinoffPulse {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spinPulse {
  0% {
    -webkit-transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #2187e7; }
  50% {
    -webkit-transform: rotate(145deg);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(-320deg);
    opacity: 0; } }

@-webkit-keyframes spinoffPulse {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

.notification-block {
  display: block;
  padding: 1rem;
  margin: 0 !important; }
  .notification-block.errorMessage {
    background-color: #ee6e73; }
  .notification-block.message {
    background-color: #2187e7; }
  .notification-block.update {
    background-color: #0C2731; }
    .notification-block.update a {
      color: #7ca8d4; }
  .notification-block p {
    color: white; }
  .notification-block a {
    top: 8px; }

.animate-in {
  -webkit-animation: fadeIn .5s ease-in;
  animation: fadeIn .5s ease-in; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.miim-progress .progress {
  height: 25px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  border: 2px solid white; }
  .miim-progress .progress .determinate {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; }

.miim-progress .progress-profile span {
  float: right;
  font-size: 1rem;
  font-weight: bold;
  color: #26A69A; }

.miim-progress .profile-completed .progress-profile span {
  color: #ee6e73; }

.miim-progress .profile-completed .determinate {
  -webkit-box-shadow: 0 1px 4px #ee6e73, 0 0 40px #ee6e73 inset;
  -moz-box-shadow: 0 1px 4px #ee6e73, 0 0 40px #ee6e73 inset;
  box-shadow: 0 1px 4px #ee6e73, 0 0 40px #ee6e73 inset; }

.progress.homePage {
  position: absolute;
  top: -8px; }

.plan .price-info .striked {
  background: linear-gradient(to left top, transparent 47.75%, #ee6e73 49.5%, #ee6e73 50.5%, transparent 52.25%); }

.plan .price-info .highlight {
  font-weight: 600; }

.refine-quiz form .row {
  margin-bottom: 1rem; }

.circular--portrait {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%; }
  @media only screen and (max-width: 600px) {
    .circular--portrait {
      margin: 0 auto; } }

.circular--portrait img {
  width: 100%;
  height: 100%; }

.image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .3s ease;
  opacity: 1; }
  .image-overlay .image-icon {
    color: #e0e0e0;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center; }

@media only screen and (min-width: 601px) {
  .webkit-right {
    text-align: -webkit-right !important;
    text-align: -moz-right !important; } }

hr {
  border-top: 1px solid #f4f4f4; }

.vertical-align-middle {
  vertical-align: middle; }

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px; }

.settings-tray {
  background: #eee;
  padding: 8px 15px;
  border-radius: 7px; }
  .settings-tray .no-gutters {
    padding: 0; }
  .settings-tray--right {
    float: right; }
    .settings-tray--right i {
      margin-top: 10px;
      font-size: 25px;
      color: grey;
      margin-left: 14px;
      transition: .3s; }
      .settings-tray--right i:hover {
        color: #0C2731;
        cursor: pointer; }

.search-box {
  background-color: #eee;
  padding: 10px 13px; }
  .search-box input {
    height: 40px; }
  .search-box .input-wrapper {
    background: #fff;
    border-radius: 40px; }
    .search-box .input-wrapper i {
      color: grey;
      margin-left: 7px;
      vertical-align: middle; }

.search-box {
  background-color: #F4F4F4;
  padding: 10px 13px;
  margin-top: 1px; }
  .search-box .input-wrapper {
    background: #fff;
    border-radius: 40px; }
    .search-box .input-wrapper i {
      color: grey;
      margin-left: 7px;
      vertical-align: middle; }

input {
  border: none;
  border-radius: 30px; }
  input::placeholder {
    color: #e3e3e3;
    font-weight: 300;
    margin-left: 20px; }
  input:focus {
    outline: none; }

.friend-drawer {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  background: #fff;
  transition: .3s ease;
  border-bottom: 1px solid #eee; }
  .friend-drawer.active {
    border-right: 2px solid #0C2731; }
  .friend-drawer--grey {
    background: #eee;
    min-height: 70px; }
  .friend-drawer .text {
    margin-left: 12px;
    width: 70%; }
    .friend-drawer .text h6 {
      margin-top: 6px;
      margin-bottom: 0; }
    .friend-drawer .text p {
      margin: 0; }
  .friend-drawer .time {
    color: grey; }
  .friend-drawer--onhover:hover {
    cursor: pointer; }

.chat-box-tray {
  background: #eee;
  display: flex;
  align-items: baseline;
  padding: 10px 15px;
  align-items: center;
  bottom: 0; }
  .chat-box-tray input {
    margin: 0 10px;
    padding: 6px 2px;
    background-color: white !important; }
  .chat-box-tray i {
    color: grey;
    font-size: 30px; }
    .chat-box-tray i:last-of-type {
      margin: 0 5px 0 8px; }
  .chat-box-tray .btn-flat.send {
    margin: 0;
    padding: 0; }
  .chat-box-tray .emoji-categories {
    height: 50px; }

.connexions {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  line-height: 28px !important; }
  .connexions::before {
    border-bottom: 1px dashed #ccc;
    content: "";
    flex-grow: 1;
    height: 1em;
    order: 2;
    margin: 0 3px; }
  .connexions div:last-child {
    order: 3;
    padding-top: 0.5em;
    font-weight: bold;
    color: black; }

.chat-list {
  overflow: scroll; }
  @media only screen and (min-width: 601px) {
    .chat-list {
      height: calc(100vh - 303px); } }
  .chat-list .text {
    font-size: 12px; }

.connexions {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  line-height: 1; }
  .connexions::before {
    border-bottom: 1px dashed #ccc;
    content: "";
    flex-grow: 1;
    height: 1em;
    order: 2;
    margin: 0 3px; }
  .connexions div:last-child {
    order: 3;
    padding-top: 0.5em;
    font-weight: bold;
    color: black; }

.generic-connect img {
  border-radius: 2%; }

.generic-connect .btn-large {
  margin-top: 2rem; }

.network .sort-search {
  color: gray; }
  .network .sort-search:hover {
    cursor: pointer; }
  .network .sort-search i {
    font-size: 35px; }

i.material-icons {
  vertical-align: middle;
  position: relative;
  top: -1px; }

.dark-blue.active {
  background-color: #cccccc;
  color: #0C2731; }

#group_add,
#mode_edit,
#message {
  overflow: visible !important; }
  #group_add .badge,
  #mode_edit .badge,
  #message .badge {
    position: absolute !important;
    right: 0;
    top: -15px; }

.collection.user-request .request-secondary-content {
  text-align: right; }
  .collection.user-request .request-secondary-content a {
    margin-left: 5px; }

.box.network .underline:last-child {
  border: none;
  padding-bottom: 0; }

p {
  overflow-wrap: break-word; }

.collection .collection-item.mobile img {
  height: 42px;
  width: 42px; }

.collection .collection-item.mobile .title {
  position: relative;
  top: -15px;
  left: 14px; }

.collection .collection-item.mobile .avatar {
  min-height: 84px; }

.collection-item button {
  margin-left: 5px; }

.select-wrapper ul {
  padding: 0; }
  .select-wrapper ul li {
    list-style: none; }

.vertically-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.no-scroll {
  overflow: hidden; }

.secondary {
  color: #26A69A; }

.clear-fix:before,
.clear-fix:after {
  display: table;
  content: ""; }

.clear-fix:after {
  clear: both; }

.no-margin-top {
  margin-top: 0;
  padding-top: 0; }

.no-margin-bottom {
  margin-bottom: 0;
  padding-bottom: 0; }

.no-margin-right {
  margin-right: 0 !important;
  padding-right: 0 !important; }

.no-margin-left {
  margin-left: 0 !important;
  padding-left: 0 !important; }

span.badge {
  background-color: #ee6e73;
  color: white !important;
  border-radius: 50%;
  min-width: 25px !important;
  min-height: 25px !important; }
  @media only screen and (min-width: 993px) {
    span.badge.menu-item {
      position: relative;
      top: -10px;
      left: -3px; } }

.underline {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 1rem; }

.box a:last-child .underline {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0; }

.right-space span {
  padding-right: .6rem; }

.is-flex-center {
  display: flex;
  align-items: center; }

.right-align {
  float: right; }

.btn-blue {
  background-color: #0C2731; }

.merriweather {
  font-family: 'Merriweather', serif; }

.equal-split {
  display: flex;
  justify-content: space-between;
  align-items: baseline; }

.push-down {
  margin-bottom: 1rem; }

.close-modal {
  position: absolute;
  top: 5px;
  right: 5px; }
  .close-modal i {
    font-size: 2rem; }

.message-holder {
  background-color: #26A69A;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  margin-bottom: 1rem; }
  .message-holder p {
    color: white;
    line-height: 3rem;
    text-align: center; }

.blue-title {
  color: #0C2731; }

.first-letter::first-letter {
  text-transform: uppercase; }

.center.top {
  text-transform: uppercase;
  position: relative;
  top: -2rem; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 16px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 4px;
  border: 1px solid #585858;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #585858 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
