
.vertically-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-scroll {
  overflow: hidden;
}

.secondary {
  color: color(secondary)
}

.clear-fix:before,
.clear-fix:after {
  display: table;
  content: "";
}

.clear-fix:after {
  clear: both;
}

.no-margin-top{
  margin-top: 0;
  padding-top: 0;
}

.no-margin-bottom{
  margin-bottom: 0;
  padding-bottom: 0;
}

.no-margin-right{
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.no-margin-left{
  margin-left: 0 !important;
  padding-left: 0 !important;
}

span.badge {
  &.menu-item {
    @media #{$large-and-up} {
      position: relative;
      top: -10px;
      left: -3px;
    }
  }
  background-color: $danger;
  color: white !important;
  border-radius: 50%;
  min-width: 25px !important;
  min-height: 25px !important;
}
.underline {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 1rem;
}
.box {
  a{
    &:last-child {
      .underline {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
.right-space {
  span {
    padding-right: .6rem;
  }
}

.is-flex-center {
  display: flex;
  align-items: center;
}

.right-align {
  float: right;
}

.btn-blue {
  background-color: $title-color;
}
.merriweather {
  font-family: 'Merriweather', serif;
}

.equal-split {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.push-down {
  margin-bottom: 1rem;
}

.close-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  i {
    font-size: 2rem;
  }
}
.message-holder {
  background-color: $secondary;
  @include round-corners(3px);
  margin-bottom: 1rem;
  p{
    color: white;
    line-height: 3rem;
    text-align: center;
  }
}

.blue-title {
  color: $title-color;
}
.first-letter::first-letter {
  text-transform: uppercase;
}

.center {
  &.top {
    text-transform: uppercase;
    position: relative;
    top: -2rem
  }
}

//loader.
.lds-ring {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 16px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 4px;
  border: 1px solid $paragraph;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $paragraph transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}