footer.page-footer{
  // background-color: color(primary-dark);
  background-color:color(dark-blue);

  .brand-logo {

    img{
      width: 128px;
    }

  }

  p,
  a{
    color: rgba($color: #FFFFFF, $alpha: 0.5)
  }

  .container{
    padding: 100px 0 100px 0;
  }

  h5{
    margin-bottom: 30px;
  }

  ul.footer_right_menu{

    li{
      margin-bottom: 10px;
    }
  }

  ul.footer_social_menu{

    li{
      display: inline;
      margin-left: 8px;
    }
  }

  .footer-copyright{
    font-size: 12px;
    background-color:color(darker-blue);

    .container{
      padding: 20px 0 20px 0;
    }
  }

  .right{
    float: none !important;
  }
}