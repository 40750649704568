input[type="range"]{

  &::-moz-focus-inner {
    border: 0;
  }

  &::-webkit-slider-thumb {
    @media #{$medium-and-down} {
      width: 20px;
      height: 20px;
      margin-top: -8px;
    }
  }

  &::-moz-range-thumb {
    @media #{$medium-and-down} {
      width: 20px;
      height: 20px;
      margin-top: -8px;
    }
  }

  &::-ms-thumb {
    @media #{$medium-and-down} {
      width: 20px;
      height: 20px;
      margin-top: -8px;
    }
  }  

  &+ .thumb{
    .value {
  
      display: block;
      width: 30px;
      text-align: center;
      color: #fff;
      font-size: 14px !important;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
  
    }
  
    &.active .value{
  
      margin-left: 0px;
      margin-top: 0px;
    }
  }

}

input[type="range"] + .thumb{
  .value {

    display: block;
    width: 30px;
    text-align: center;
    color: #fff;
    font-size: 14px !important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);

  }

  &.active .value{

    margin-left: 0px;
    margin-top: 0px;
  }
}

.semi-donut{
  --percentage: 0;
  --fill: #AB2171;
  width: 300px;
  height: 150px;
  position: relative;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
  &:after{
    content: '';
    width: 300px;
    height: 300px;
    border:50px solid;
    border-color : rgba(0,0,0,0.15) rgba(0,0,0,0.15) var(--fill) var(--fill);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing : border-box;
    transform: rotate( calc( 1deg * ( -45 + var(--percentage) * 1.8 ) ) );
    animation : fillAnimation 1s ease-in;
  }
}

.match {
  td {
    background-repeat: no-repeat;
    transition-delay: 1.2s;
  }
  td:nth-child(2) {
    width: 30%;
    text-align: right;
    background-image: linear-gradient(to left, #E5E5E5, #E5E5E5);
    background-position: right top;
  }

  td:last-child {
    width: 30%;
    background-image: linear-gradient(to right, #E5E5E5, #E5E5E5);
    background-position: left top;
  }
  .cellDifference {
    background-image: linear-gradient(to left, #CA377E, #CA377E) !important;
    color: white;
  }
  .cellSimilarity {
    background-image: linear-gradient(to left, $secondary, $secondary) !important;
    color: white;
  }
}

