.btn{
  color: #ffffff;
  padding: 0 20px 0 20px;
  border-radius: 30px;

  &.full {
    width: 100%;
  }

  &.dark-blue {
    background-color: #0C2731;
  }

  &.btn-flat{
    color: color(primary);
  }

  &.btn-stroke{
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;

    &:hover{
      background: #ffffff;
      color: color(primary);
    }
  }
}

.btn-large{
  &.dark-blue {
    background-color: #0C2731;
  }
  padding: 0 40px 0 40px;
  border-radius:30px;
  font-weight: bold;
  color: #ffffff;
  background-color: color(secondary);

  &.call_to_action{

    &:hover{
      background-color: #fff;
      color: color(primary);
      box-shadow: none;
    }
    @media screen and ( max-width: 902px ){
      margin: 0 auto;
    }
  }

  &.submit{
    background-color: color(secondary);
    margin: 20px 0 20px 0;

    &:hover{
      background-color: color(primary);
      box-shadow: none;
    }
  }
}
.quiz-profileActions {
  a {
    margin-bottom: 2rem;
    width: 100%;
    &.active {
      background-color: $title-color;
    }
  }
}
.btn-group {
  a {
    margin-bottom: 1rem;
  }
}
button {
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    background-color: transparent;
  }
}