.pagination {
  display: flex;
  align-items: stretch;
  justify-content: center;
  &.stepper-quiz{
    justify-content: space-between;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;
  }
  &.stepper-refine {
    justify-content: space-between;
  }

  @media screen and ( max-width: 601px ){
    margin: 40px 0 20px 0;
  }

  &.mobile {
    &._6,
    &._7,
    &._8,
    &._9,
    &._10 {
      li {
        &:nth-child(1),
        &:nth-child(2){
          display: none;
        }
      }
    }
  }

  li{
    display: block;
    flex: 0 1 auto;
    height: auto;
    width: auto;
    position: relative;
    &.active {
      background-color: #ccc;
      border-radius: 50%;
    }
    a {
      text-decoration: none;
    }

    &.pulse {
      z-index: 9999;
    }

    .btn-large {
      box-shadow:none;
      line-height: 3rem;

      &.grey {
        background-color: #e9e8e8 !important;
        color: #ccc;
        z-index: 100
      }
    }
    .btn-small {
      box-shadow:none;
      width: 40px;
      height: 40px;
      line-height: 40px;
      i {
        font-size: 1.4rem;
        width: auto;
      }
      &.grey {
        background-color: #e9e8e8 !important;
        color: #ccc;
        z-index: 100
      }
    }
    span {
      display: block;
      color: #9c9b9b;
    }
    .active {
      font-weight: bold;
      font-size: 2em;
      color: color(secondary);

    }
  }
}

.stepper-refine {
  width: 50%;
  &.mobile {
    width: 100%;
  }
}
