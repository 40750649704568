$default_rounded_amount: 5px;

@function color($color-name) {
  @return map-get($colors, $color-name )
}

// Round corner at position by amount.
@mixin round-corner($position, $amount: $default_rounded_amount) {
  border-#{$position}-radius: $amount;
  -webkit-border-#{$position}-radius: $amount;
}

@mixin round-corner-mozilla($position, $amount: $default_rounded_amount) {
  -moz-border-radius-#{$position}: $amount;
}

// Round left corners by amount
@mixin round-left-corners($amount: $default_rounded_amount) {
  @include round-corner("top-left", $amount);
  @include round-corner("bottom-left", $amount);
  @include round-corner-mozilla("topleft", $amount);
  @include round-corner-mozilla("bottomleft", $amount);
}

// Round right corners by amount
@mixin round-right-corners($amount: $default_rounded_amount) {
  @include round-corner("top-right", $amount);
  @include round-corner("bottom-right", $amount);
  @include round-corner-mozilla("topright", $amount);
  @include round-corner-mozilla("bottomright", $amount);
}

// Round top corners by amount
@mixin round-top-corners($amount: $default_rounded_amount) {
  @include round-corner("top-left", $amount);
  @include round-corner("top-right", $amount);
  @include round-corner-mozilla("topleft", $amount);
  @include round-corner-mozilla("topright", $amount);
}

// Round top right corners by amount
@mixin round-top-corners-right($amount: $default_rounded_amount) {
  @include round-corner("top-right", $amount);
  @include round-corner-mozilla("topright", $amount);
}

// Round top left corners by amount
@mixin round-top-corners-left($amount: $default_rounded_amount) {
  @include round-corner("top-left", $amount);
  @include round-corner-mozilla("topleft", $amount);
}

// Round bottom corners by amount
@mixin round-bottom-corners($amount: $default_rounded_amount) {
  @include round-corner("bottom-left", $amount);
  @include round-corner("bottom-right", $amount);
  @include round-corner-mozilla("bottomleft", $amount);
  @include round-corner-mozilla("bottomright", $amount);
}

// Round all corners by amount
@mixin round-corners($amount: $default_rounded_amount) {
  border-radius: $amount;
  -moz-border-radius: $amount;
  -webkit-border-radius: $amount;
}

// Remove box-shadow
@mixin no-box-shadow() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}

@mixin Opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($shadow1, $shadow2:false) {
  $params: $shadow1;
  @if $shadow2
  { $params: $shadow1, $shadow2; }
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

@mixin font-awesome-icon($position, $unicode) {
  font-family: $font-awesome;
  content: $unicode;
  @if $position == 'before' {
    margin-right: 5px;
  } @else {
    margin-left: 5px;
  }
}

@mixin column-count($ct) {
  -webkit-column-count: $ct;
  -moz-column-count: $ct;
  column-count: $ct;
}
