$nav-height: (large: 110px, thin: 66px);
$circle-profile-img-size: (
        small: 48px
);

.navbar-fixed{
  position: fixed;
  width: 100%;
  &.dark {
    .nav-wrapper {
      background-color: white;
      li, a {
        color: #707070;
        &.active {
          color: black;
          font-weight: bold;
        }
      }
    }
    .brand-logo .svg{
       filter: invert(10%) sepia(45%) saturate(951%) hue-rotate(152deg) brightness(92%) contrast(92%);
    }
    nav {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
      ul {
        li {
          a {
            &:hover,
            &.active {
              color: black;
              transition: 0.3s ease-out;
            }
          }
        }
      }
    }
    .sidenav-trigger {
       color: black;
    }
  }
}


nav {
  .dropdown-content{
    &.spthinx {
      a {
        height: 66px !important;
      }
      .btn {
        height: 35px !important;
        color: white;
        &:hover {
          background-color: $primary;
        }
      }
    }
  }
  height: map-get($nav-height, large);
  line-height: map-get($nav-height, large);
  box-shadow: none;
  background-color: rgba(12,39,49,0);
  border: none;
  color: #fff;
  transition: height 0.3s ease-out, line-height 0.3s ease-out, background-color 0.3s ease-out;

  .brand-logo .svg{
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(104%);
  }
  a{
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    i{
      height: map-get($nav-height, large);
      line-height: map-get($nav-height, large);
    }
    &:hover{
      background: transparent !important;
    }

    &.sidenav-trigger{
      i{
        height: map-get($nav-height, large);
        line-height: map-get($nav-height, large);
        transition: 0.3s ease-out;
        font-size: 32px;
      }
    }
  }

  .nav-wrapper {
    padding: 0 50px 0 50px;
    @media screen and ( max-width: 992px ){
      padding: 0 5px 0 5px !important;
    }
    .brand-logo {
      margin-top: 8px;
      left: 5%;
      -webkit-transform: none;
      transform: none;

      img{
        width: 80px;
      }
    }
    ul.main_menu{
      li{
        a{
          height: map-get($nav-height, large);
          &.login_register_items{
            padding: 0 8px;
          }
          img.profile-img {
            width: map-get($circle-profile-img-size, small);
            height: map-get($circle-profile-img-size, small);
            margin-top: calc((110px - 48px) / 2);
            transition: 0.3s ease-out;
            top: -3px;
            position: relative;
          }
        }

        ul.dropdown-content{
          width: auto !important;
          li{
            a{
              height: auto;
            }
          }
        }
      }
    }
  }


  &.scroll {
    background-color: #fff;
    color:color(dark-blue);
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    height: map-get($nav-height, thin);
    line-height: map-get($nav-height, thin);

    .brand-logo .svg{
      filter: invert(10%) sepia(45%) saturate(951%) hue-rotate(152deg) brightness(92%) contrast(92%);
    }
    a{
      text-decoration: none;
      color:color(dark-blue);

      i{
        height: map-get($nav-height, thin);
        line-height: map-get($nav-height, thin);
      }

      &.sidenav-trigger{

        i{
          height: map-get($nav-height, thin);
          line-height: map-get($nav-height, thin);
          transition: 0.3s ease-out;
        }
      }
    }

    .nav-wrapper {

      padding: 0 50px 0 50px;

      @media screen and ( max-width: 902px ){
        padding: 0 5px 0 5px !important;
      }

      .brand-logo {
        margin-top: 8px;
        left: 5%;
        -webkit-transform: none;
        transform: none;

        img{
          width: 80px;
        }

      }

      ul.main_menu{
        li{
          a{
            height: map-get($nav-height, thin);

            &.login_register_items{
              padding: 0 8px;
            }

            img.profile-img {
              width: map-get($circle-profile-img-size, small);
              height: map-get($circle-profile-img-size, small);
              margin-top: calc((66px - 48px) / 2);
            }
          }
        }
      }
    }

  }
}

.sidenav{
  a{
    text-decoration: none;
    &.active {
      color: black;
      font-weight: bold;
    }
  }
}

.sidenav-overlay {
  z-index: 996;
}
.miim-mobile-sidebar {
  .collapsible {
    .collapsible-header {
      padding: 0 32px;
    }
    .collapsible-body {
      a {
        display: block;
        padding: 0 32px;
      }
    }
  }
  a, .collapsible-header {
    text-transform: uppercase;
  }
}

.logout {
  i {
    font-size: 16px;
  }
}
.sidenav {
  i {
    margin: 0 !important;
  }
}

#spthinx {
  li {
    &:hover {
      background-color: transparent !important;
    }
    a {
      text-transform: capitalize;

    }
  }
}

.arrow-down-after {
  &:after {
    font-family: 'Material Icons', serif;
    content: "\e5c5";
  }
}
