$colors: (
        primary: #541E69,
        primary-light: lighten(#541E69, 65%),
        primary-dark: #0d0a1c,
        danger: #ee6e73,
        secondary: #26A69A,
        paragraph: #585858,
        link-gray: #666666,
        gray-background: #F4F4F4,
        placeholder: #5c5c5c,
        links-and-labels: #9e9e9e,
        labels: #BFBFBF,
        light-grey: #ebebeb,
        title-color: #0C2731,
        dark-blue: #0C2731,
        darker-blue: #081c24,
);

//Colors.
$primary: #541E69;
$primary-light: lighten(#541E69, 65%);
$primary-dark: #0d0a1c;
$danger: #ee6e73;
$secondary: #26A69A;
$paragraph: #585858;
$link-gray: #666666;
$gray-background: #F4F4F4;
$placeholder: #5c5c5c;
$links-and-labels: #9e9e9e;
$labels: #BFBFBF;
$light-grey: #ebebeb;
$title-color: #0C2731;
$dark-blue: #0C2731;
$darker-blue: #081c24;

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

$font-awesome: 'FontAwesome';
