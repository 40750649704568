@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,800,900');
@import url('https://fonts.googleapis.com/css?family=Merriweather:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

.miim-content-node-box {
  .title {
    margin-bottom: 5rem;
    color: color(primary-dark);
  }
  h3 {
    font-size: 2em;
  }
  li{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 28px;
    color: color(paragraph);
  }
  ul {
    padding-left: 2rem;
    li {
      list-style-type: disc;
    }
  }
  ol {
    padding-left: 2rem;
    li {
      color: color(text-color);
    }
  }
}

a {
  color: color(links-and-labels);
  text-decoration: underline;
  font-size: 1rem;

  &.view_more{
    display: block;
    margin-top: 16px;
    color: color(secondary);
  }
}

p{
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 28px;
  color: color(paragraph);
}



.pad{
  &.left-align{

    p,
    h2,
    h3{
      text-align: left;
    }

    ul{
      margin-top: 20%;

      @media screen and ( max-width: 786px ){
        margin-top: 10%;
      }
      @media (max-width: 601px) {
        margin-top: 0;
      }
      li{
        font-family: 'Merriweather', serif;
        font-size: 28px;
        line-height: 40px;
        font-weight: 300;
        color: color(title-color);
        list-style-type: none;
        margin-bottom: 1rem;
        text-align: left;
        @media screen and ( max-width: 1114px ){
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 1.4rem;
        }
      }
    }
  }

  &.right-align{

    p,
    h2,
    h3{
      text-align: right;
    }

    ul{

      margin-top: 20%;

      @media screen and ( max-width: 786px ){
        margin-top: 10%;
      }

      @media (max-width: 601px) {
        margin-top: 0;
      }

      li{
        font-family: 'Merriweather', serif;
        font-size: 28px;
        line-height: 40px;
        font-weight: 300;
        color: color(title-color);
        list-style-type: none;
        display: inline-flex;
        margin-bottom: 1rem;
        max-width: 80%;
        text-align: right;

        @media screen and ( max-width: 1114px ){
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 1.4rem;
        }
      }
    }
  }
}

h1{
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 36px;
  color: #ffffff;

  &.error_page_title{
    text-transform: none;
    margin-bottom: 60px;
  }

  &.error_page_number{
    font-size: 200px;
    margin-bottom: 120px;

    @media screen and ( max-width: 601px ){
      font-size: 40vw;
    }
  }

  &.page_title{
    &.profile_title{
      margin-top: 200px;
      margin-bottom: 0;

      @media screen and ( max-width: 992px ){
        margin-top: 100px;
      }

      @media screen and ( max-width: 601px ){
        //margin-top: 0px;
      }

    }

    @media screen and ( max-width: 992px ){
      font-size: 32px !important;
      line-height: 40px !important;
      font-weight: 600 !important;
    }
  }

  &.account_page{
    span {
      font-weight: lighter;
      color: color(primary);
      font-size: 2rem;
    }
    @media screen and ( max-width: 992px ){
      text-align: center;
    }

  }


  &.baseline{
    @media #{$small-and-down} {
      font-size: 32px;
    }
      text-align: center;
      font-size: 38px;
      line-height: 56px;
  }
}

h2{
  font-family: 'Merriweather', serif;
  font-size: 54px;
  line-height: 70px;
  font-weight: 300;
  display: block;
  margin: 0 auto;
  margin-bottom: 3rem;
  color: color(title-color);

  @media screen and ( max-width: 992px ){
    font-size: 50px;
    line-height: 64px;
    margin-bottom: 2rem;
  }
}

h3{
  font-family: 'Merriweather', serif;
  font-size: 34px;
  font-weight: 300;
  color: color(title-color);
  margin-bottom: 2rem;

  &.thin{
    font-size: 26px;
    line-height: 40px;
    font-weight: 100;
    margin-top: 12px;
    margin-bottom: 1.6rem;
    color:color(title-color);

    @media screen and ( max-width: 992px ){
      font-size: 22px !important;
    }
  }

  span.injunction{
    font-size: 16px;
  }

  &.fixed_height{
    height: 80px;
  }
}

h4{
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 1.5rem;
  color: #444444;
  padding: 0 0 0 1rem;
}

.upgrade-wrapper {
  h1 {
    color: black;
  }
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons', serif;
  font-size: 18px;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
