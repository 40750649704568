.logged_in{
  main{

    padding-top: 150px;

    @media screen and ( max-width: 601px ){
      padding-top: 80px;
    }
  }
  nav {
    background-color: rgba(255, 255, 255, 1);
    color: #707070;

    @media screen and ( max-width: 992px ){
      background-color: rgba(255, 255, 255, 1);
    }

    &.scroll {

      @media screen and ( max-width: 992px ){
        background-color: rgba(255, 255, 255, 1);
      }
    }

    .brand-logo .svg{
      filter: invert(10%) sepia(45%) saturate(951%) hue-rotate(152deg) brightness(92%) contrast(92%);
    }

    a{
      color: #707070;
    }

    .brand-logo {
      margin-top: 8px;
      left: 5%;
      -webkit-transform: none;
      transform: none;

      img{
        width: 80px;
        fill: red;
      }
    }
  }

  $bg-images: header-profile-01-closed header-profile-02-closed header-profile-03-closed header-profile-04-closed header-profile-05-closed header-profile-06-closed header-profile-07-closed header-profile-08-closed
  header-profile-09-closed header-profile-10-closed header-profile-11-closed header-profile-12-closed header-profile-13-closed;
  @each $bg-image in $bg-images {
    .#{$bg-image} {
      &.profile_bg {
        background: url("../images/profile-bg/#{$bg-image}.jpg") no-repeat center center;
        background-size: cover;
        box-shadow: inset 0 0 0 50vw rgba(0,0,0,0.15);
      }
    }
  }

  $spthinx-images: header-behavior-01-setting header-behavior-02-setting header-behavior-03-setting
  header-behavior-04-setting header-behavior-05-setting header-knack-01-setting
  header-knack-02-setting header-knack-03-setting header-knack-04-setting header-knack-05-setting
  header-knack-06-setting header-knack-07-setting header-knack-08-setting header-knack-09-setting header-knack-10-setting
  header-knack-11-setting header-knack-12-setting header-knack-13-setting header-relational-pattern-01-setting
  header-relational-pattern-02-setting header-relational-pattern-03-setting header-relational-pattern-04-setting
  header-relationship-01-setting header-relationship-02-setting header-relationship-03-setting header-relationship-04-setting
  header-relationship-05-setting header-stress-01-setting header-stress-02-setting header-stress-03-setting header-stress-04-setting
  header-stress-05-setting header-stress-06-setting header-stress-07-setting header-stress-08-setting header-stress-09-setting
  header-stress-10-setting header-stress-11-setting header-stress-12-setting header-stress-13-setting header-weakness-01-setting
  header-weakness-02-setting header-weakness-03-setting header-weakness-04-setting header-weakness-05-setting header-weakness-06-setting
  header-weakness-07-setting header-weakness-08-setting header-cognition-01-setting header-cognition-02-setting header-cognition-03-setting
  header-cognition-04-setting  header-cognition-05-setting  header-cognition-06-setting  header-cognition-07-setting  header-cognition-08-setting
  header-cognition-09-setting header-cognition-10-setting  header-cognition-11-setting  header-cognition-12-setting  header-cognition-13-setting
  header-cognition-14-setting;
  @each $spthinx-image in $spthinx-images {
    .#{$spthinx-image} {
      &.spthinx_bg {
        background: url("../images/riddle/#{$spthinx-image}.jpg") no-repeat center center;
        background-size: cover;
        box-shadow: inset 0 0 0 50vw rgba(0,0,0,0.15);
      }
    }
  }

  .profile_bg,
  .spthinx_bg {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 600px;
    z-index: -1;

    @media screen and ( max-width: 992px ){
      min-height: 420px;
    }
  }


  h1{
    &.page_title{
      color: $title-color;
    }
  }
}