.divider {
  background-color: color(light-grey);
  margin: 30px 0 30px 0;
}

.input-field > input[type]:-webkit-autofill:not(.browser-default):not([type="search"]) + label,
.input-field > input[type=date]:not(.browser-default) + label, .input-field > input[type=time]:not(.browser-default) + label {
  -webkit-transform: translateY(-22px) scale(0.8);
}

.datepicker-container{
  input:not([type]),
  input[type=text]:not(.browser-default),
  input[type=password]:not(.browser-default),
  input[type=email]:not(.browser-default),
  input[type=url]:not(.browser-default),
  input[type=time]:not(.browser-default),
  input[type=date]:not(.browser-default),
  input[type=datetime]:not(.browser-default),
  input[type=datetime-local]:not(.browser-default),
  input[type=tel]:not(.browser-default),
  input[type=number]:not(.browser-default),
  input[type=search]:not(.browser-default),
  textarea.materialize-textarea {
    background-color: inherit;
  }
}

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  &:focus {
    border: 1px solid $secondary;
    box-shadow: none !important;
  }
  &.invalid {
    border: 1px solid $danger;
    box-shadow: none;
  }
  background-color: rgba(235,235,235,0.7);
  color: color(placeholder);
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 92%;
  padding: 0 4% 0 4%;
  line-height: 50px;
  height: 50px;
}


.input-field > label:not(.label-icon).active,
input[type="text"]:not(.browser-default).validate + label,
input[type="password"]:not(.browser-default).validate + label,
input[type="email"]:not(.browser-default).validate + label,
.input-field > label
{
  -webkit-transform: translateY(-22px) scale(0.8);
  transform: translateY(-22px) scale(0.8);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;

  &.select_label{
    -webkit-transform: translateY(4px) scale(1);
    transform: translateY(4px) scale(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}



.input-field.col label {
  padding-left: 1%;
  color: color(labels);
}

select option .disabled{
  color: color(placeholder);
}

/* Placeholder */
::-webkit-input-placeholder {
  color:color(placeholder);
}
:-moz-placeholder {
  color:color(placeholder);
  opacity:  1;
}
::-moz-placeholder {
  color:color(placeholder);
  opacity:  1;
}
:-ms-input-placeholder {
  color:color(placeholder);
}
::-ms-input-placeholder {
  color:color(placeholder);
}
::placeholder {
  color:color(placeholder);
}

.select-wrapper .caret {
  right: 3%;
  fill: black
}


.white_field {
  input[type=text]:not(.browser-default){
    background-color: rgba(255, 255, 255, 0.7);
  }
  textarea{
    background-color: #ffffff;
    height: auto;
    line-height: 1.8rem;
    padding: 20px 20px;
    width: 100%;
  }
}


.form_footer{

  ul{
    font-size: 1rem;
    li{
      list-style-type: none;
      label{

        span{
          line-height: normal !important;
          height: auto !important;

          @media screen and ( max-width: 400px ){
            padding-left: 22px !important;
          }

          &::after{
            top: 50% !important;
            margin-top: -10px !important;

            @media screen and ( max-width: 400px ){
              margin-top: -8px !important;
              height: 16px !important;
              width: 16px !important;
            }
          }
        }
      }
    }
  }
}

.is-safari {
  .miim-register-terms-conditions-block {
    margin-top: 1rem;
    ul {
      margin: 0;
    }
  }
}
.miim-register-terms-conditions-block,
.login {
  ul {
    display: inline-table;
  }
  a {
    margin-left: 4px;
  }
}

[type=radio] + img {
  cursor: pointer;
  @include border-radius(5px);
  padding: 5px;
  border: solid 1px $gray-background;
  -moz-box-shadow: 1px 1px 5px #999;
  -webkit-box-shadow: 1px 1px 5px #999;
  box-shadow: 1px 1px 5px #999;
  &:hover {

  }
}
[type=radio]:checked + img {
  border: solid 2px $dark-blue;
}

.network {
  .materialize-textarea {
    min-height: 200px;
    width: 100%;
    line-height: 26px;
  }
}