.btn.social > :first-child,
.btn-large.social > :first-child,
.col.social > :first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.btn.social,
.btn-large.social,
.col.social {
  padding: 0 2rem 0 0;
}
.btn.social i,
.btn-large.social i,
.col.social i {
  padding: 0 1rem;
  margin-right: 1rem;
}
.btn.social-icon,
.btn-large.social-icon,
.col.social-icon {
  padding: 0;
}
.btn.social-icon i,
.btn-large.social-icon i,
.col.social-icon i {
  padding: 0 1rem;
  margin-right: 0;
}
.btn-large.social-icon {
  padding: 0 1rem;
}
.col.social {
  width: 100%;
  text-align: left;
}
.col.social i.fa-fw {
  width: 3.2rem;
}
.adn {
  background-color: #d87a68;
  color: #fff !important;
}
.adn i {
  color: #fff !important;
}
.adn:hover {
  background-color: #e29e91 !important;
}
.bitbucket {
  background-color: #205081;
  color: #fff !important;
}
.bitbucket i {
  color: #fff !important;
}
.bitbucket:hover {
  background-color: #2a69aa !important;
}
.dropbox {
  background-color: #1087dd;
  color: #fff !important;
}
.dropbox i {
  color: #fff !important;
}
.dropbox:hover {
  background-color: #309ff0 !important;
}
.facebook {
  background-color: #3b5998;
  color: #fff !important;
}
.facebook i {
  color: #fff !important;
}
.facebook:hover {
  background-color: #4c70ba !important;
}
.flickr {
  background-color: #ff0084;
  color: #fff !important;
}
.flickr i {
  color: #fff !important;
}
.flickr:hover {
  background-color: #ff339d !important;
}
.foursquare {
  background-color: #f94877;
  color: #fff !important;
}
.foursquare i {
  color: #fff !important;
}
.foursquare:hover {
  background-color: #fb799c !important;
}
.github {
  background-color: #444444;
  color: #fff !important;
}
.github i {
  color: #fff !important;
}
.github:hover {
  background-color: #5e5e5e !important;
}
.google {
  background-color: #dd4b39;
  color: #fff !important;
}
.google i {
  color: #fff !important;
}
.google:hover {
  background-color: #e47365 !important;
}
.instagram {
  background-color: #3f729b;
  color: #fff !important;
}
.instagram i {
  color: #fff !important;
}
.instagram:hover {
  background-color: #548cb9 !important;
}
.linkedin {
  background-color: #007bb6;
  color: #fff !important;
}
.linkedin i {
  color: #fff !important;
}
.linkedin:hover {
  background-color: #009de9 !important;
}
.microsoft {
  background-color: #2672ec;
  color: #fff !important;
}
.microsoft i {
  color: #fff !important;
}
.microsoft:hover {
  background-color: #5590f0 !important;
}
.windows {
  background-color: #2672ec;
  color: #fff !important;
}
.windows i {
  color: #fff !important;
}
.windows:hover {
  background-color: #5590f0 !important;
}
.odnoklassniki {
  background-color: #f4731c;
  color: #fff !important;
}
.odnoklassniki i {
  color: #fff !important;
}
.odnoklassniki:hover {
  background-color: #f6914d !important;
}
.openid {
  background-color: #f7931e;
  color: #fff !important;
}
.openid i {
  color: #fff !important;
}
.openid:hover {
  background-color: #f9ab4f !important;
}
.pinterest {
  background-color: #cb2027;
  color: #fff !important;
}
.pinterest i {
  color: #fff !important;
}
.pinterest:hover {
  background-color: #e03e44 !important;
}
.reddit {
  background-color: #eff7ff;
  color: #000 !important;
}
.reddit i {
  color: #000 !important;
}
.reddit:hover {
  background-color: #ffffff !important;
}
.soundcloud {
  background-color: #ff5500;
  color: #fff !important;
}
.soundcloud i {
  color: #fff !important;
}
.soundcloud:hover {
  background-color: #ff7733 !important;
}
.tumblr {
  background-color: #2c4762;
  color: #fff !important;
}
.tumblr i {
  color: #fff !important;
}
.tumblr:hover {
  background-color: #3c6085 !important;
}
.twitter {
  background-color: #55acee;
  color: #fff !important;
}
.twitter i {
  color: #fff !important;
}
.twitter:hover {
  background-color: #83c3f3 !important;
}
.vimeo {
  background-color: #1ab7ea;
  color: #fff !important;
}
.vimeo i {
  color: #fff !important;
}
.vimeo:hover {
  background-color: #49c6ee !important;
}
.vk {
  background-color: #587ea3;
  color: #fff !important;
}
.vk i {
  color: #fff !important;
}
.vk:hover {
  background-color: #7897b6 !important;
}
.yahoo {
  background-color: #720e9e;
  color: #fff !important;
}
.yahoo i {
  color: #fff !important;
}
.yahoo:hover {
  background-color: #9412cd !important;
}