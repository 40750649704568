.top_banner{
  background: url('../images/top15_bg.svg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin-top: -20vh;
  @media screen and ( max-width: 601px ){
    margin-top: -12vh;
  }
  .container{
    height: calc(70vh + 180px);
    padding-top: 20vh;
    @media screen and ( max-width: 601px ){
      padding-top: 6vh;
    }
    .wrapper_call_to_action{
      .miim-into {
        p:first-child {
          color: red;
          position: relative;
          line-height: 28px;
          color: color(secondary);;
          font-size: 1.8rem;
          font-weight: bold;
          padding-bottom: 2rem;
        }
        p {
          color: white;
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
  }
}

